import React, { useState, useEffect } from 'react';

const DateTimeDisplay = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // Use useEffect to update the current date and time every second (1000 milliseconds)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (

      <span>{currentDateTime.toLocaleString()}</span>

  );
};

export default DateTimeDisplay;
