import React from 'react'
import Nav from '../Components/Nav';
import InvestDeposit from '../Components/InvestDeposit';

function Myplans() {
  return (
  
    <div>
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <title> </title>
    <div className="wrapper">
        <Nav />         
      <div id="loader" className="text-center card" style={{position: 'fixed', display: 'none', left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999, opacity: '0.855'}}>
        <div className="text-info text-center">
          <img className style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-40px', marginLeft: '-40px'}} src="../util/logo/logo-icon.png" height="auto" width="60px" />
        </div>
      </div>
      <div className="main-panel">
        <div className="container">
          <div className="bg-primary2 pt-4 pb-5" style={{marginBottom: '-80px'}}>
            <div className="container text-white py-2">
              <div className="d-flex align-items-center">
                <div className="mr-3">
                  <h2 className="mt-1">Investment Plans</h2>
                </div>
                <div className="ml-auto">
                </div>
              </div>
            </div>
          </div>                            
          <div className="page-inner mt--5">
   <InvestDeposit/>
          </div>
        </div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="language langBox" style={{}}>
              <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{height: '50px', width: 'auto'}} />
              <div id="google_translate_element" />
            </div>
            <nav className="pull-left">
              <ul className="nav">
                <li className="nav-item">
                  <a className="nav-link" href="support.html">
                    Contact Support
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="Refferals">
                    My Referral
                  </a>
                </li>
              </ul>
            </nav>
            <div className="copyright ml-auto">
              2024made with <a href="home.html">Gravityfinances</a>
            </div>				
          </div>
        </footer>                    
      </div>
    </div>
    <div className="watsPanel">
      <a href="#langDiv">
        <img className="img-fluid mb-0" src="../images/icons/lang.png" alt="" />
      </a>
      <hr className="m-2" />
      <a href="https://wa.me/+447442264929" id="heheh">
        <img className="img-fluid" src="../images/icons/whatsapp.png" alt="" />
      </a>
      <a href="https://wa.me/+447442264929">
        <img className="img-fluid" src="../images/icons/whatsapp-2.png" alt="" />
      </a>
    </div>
    {/* jQuery UI */}
    {/* jQuery Scrollbar */}
    {/* Moment JS */}
    {/* Chart JS */}
    {/* jQuery Sparkline */}
    {/* Chart Circle */}
    {/* Datatables */}
    {/* Bootstrap Notify */}
    {/* Bootstrap Toggle */}
    {/* jQuery Vector Maps */}
    {/* Google Maps Plugin */}
    {/* Dropzone */}
    {/* Fullcalendar */}
    {/* DateTimePicker */}
    {/* Bootstrap Tagsinput */}
    {/* Bootstrap Wizard */}
    {/* jQuery Validation */}
    {/* Summernote */}
    {/* Select2 */}
    {/* Sweet Alert */}
    {/* Owl Carousel */}
    {/* Magnific Popup */}
    {/* Atlantis JS */}
    {/* Code provided by Google */}
    {/* End custom js for this page */}
  </div>
  )
}

export default Myplans