import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes, Switch } from "react-router-dom";
import { GlobalState } from "./Globalstate";
import AppContainer from "./AppContainer";
import Dashboard from "./Pages/Dashboard";
import Deposit from "./Pages/Deposit";
import Myplans from "./Pages/Myplans";
import Profile from "./Pages/Profile";
import ProfitsHistory from "./Pages/ProfitsHistory";
import Refferals from "./Pages/Refferals";
import Transactions from "./Pages/Transactions";
import TransferFunds from "./Pages/TransferFunds";
import TransferHistory from "./Pages/TransferHistory";
import Withdrawal from "./Pages/Withdrawal";
import DepositHistory from "./Pages/DepositHistory";
import WithdrawalHistory from "./Pages/WithdrawalHistory";
import CompleteDeposit from "./Pages/CompleteDeposit";
import "./App.css"


function App() {

  return (
    <GlobalState>
      {/* <ToastContainer className="foo" /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<AppContainer />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/DepositHistory" element={<DepositHistory />} />
          <Route path="/CompleteDeposit" element={<CompleteDeposit />} />
          <Route path="/Myplans" element={<Myplans/>} />
          <Route path="/Profile" element={<Profile/>} />
          <Route path="/ProfitsHistory" element={<ProfitsHistory />} />
          <Route path="/Refferals" element={<Refferals />} />
          <Route path="/Transactions" element={<Transactions/>} />
          <Route path="/TransferFunds" element={<TransferFunds/>} />
          <Route path="/TransferHistory" element={<TransferHistory/>} />
          <Route path="/Withdrawal" element={<Withdrawal/>} />
          <Route path="/WithdrawalHistory" element={<WithdrawalHistory/>} />
        </Routes>
      </BrowserRouter>
    </GlobalState>
  );
}

export default App;
