import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyDjWAsWhdHyxIOlpV3Eia4EXa7vbZLwC3E",
  authDomain: "gravityfinances-90eb8.firebaseapp.com",
  databaseURL: "https://gravityfinances-90eb8-default-rtdb.firebaseio.com",
  projectId: "gravityfinances-90eb8",
  storageBucket: "gravityfinances-90eb8.appspot.com",
  messagingSenderId: "1047204055157",
  appId: "1:1047204055157:web:0224d1a5b83a7a488bd03c"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()