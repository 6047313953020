import React from 'react'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { MarketOverview } from "react-ts-tradingview-widgets";
import { SingleTicker } from "react-ts-tradingview-widgets";


function Tradingview() {
  return (
    <div>              <div className="row">
    <div className="col-md-8">
      <div className="card">
        <div className="card-header">
          <div className="card-head-row">
            <div className="card-title">Real Time Chart</div>
          </div>
        </div>
        <div className>
          <div className="chart-container" style={{ minHeight: '100%' }}>
            {/* TradingView Widget BEGIN */}
            <AdvancedRealTimeChart theme="dark" autosize></AdvancedRealTimeChart>
            {/* TradingView Widget END */}
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-4">
      <div className="card ">
        <div className="card-header">
          <div className="card-head-row">
            <div className="card-title">Stock Market</div>
          </div>
        </div>
        <div className>
          <div className="chart-container" style={{ minHeight: '250px' }}>
            {/* TradingView Widget BEGIN */}
            <MarketOverview colorTheme="dark" height={400} width="100%" showFloatingTooltip></MarketOverview>
            {/* TradingView Widget END */}
          </div>
        </div>
      </div>
      <div className="card card-default">
        <div className="card-body">
          {/* TradingView Widget BEGIN */}
          <SingleTicker colorTheme="dark" width="100%"></SingleTicker>
          {/* TradingView Widget END */}
        </div>
      </div>
    </div>
  </div></div>
  )
}

export default Tradingview